import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { BlogPostFilter, PostListQueryDocument } from 'src/generated';

export const PUBLISHED_FILTER = {
  nodePtr: { published: { equalTo: true } },
} as const;

export function withPublishedFilter(filter?: BlogPostFilter | null) {
  return filter ? { and: [filter, PUBLISHED_FILTER] } : PUBLISHED_FILTER;
}

type UsePostListQueryOptions = {
  filter?: BlogPostFilter;
  initialLimit?: number;
  initialOffset?: number;
  pageSize?: number;
};

export default function usePostListQuery({
  filter,
  pageSize = 10,
  initialLimit = pageSize,
  initialOffset = 0,
}: UsePostListQueryOptions = {}) {
  const [limit, setLimit] = useState(initialLimit);

  const { fetchMore: rawFetchMore, ...rest } = useQuery(PostListQueryDocument, {
    variables: {
      limit,
      offset: initialOffset,
      filter: withPublishedFilter(filter),
    },
  });

  const fetchMore = () => {
    const currentLength = rest.data?.blogPosts?.nodes.length || 0;
    rawFetchMore({
      variables: {
        limit: pageSize,
        offset: currentLength + 1,
      },
    })
      .then((fetchMoreResult) => {
        const { nodes } = fetchMoreResult.data.blogPosts || {};
        if (nodes) {
          setLimit(currentLength + nodes.length);
        }
      })
      .catch(console.error);
  };

  return { ...rest, fetchMore };
}
