import { MetaHTMLAttributes, useContext } from 'react';
import classNames from 'classnames';
import Head from 'next/head';
import type { Blog, WithContext } from 'schema-dts';
import { useQuery } from '@apollo/client';

import logo from 'src/images/NewHeader_filledfont2_cropped@2x.png';

import { SITE_URL, site } from 'src/config/siteInfo';
import { ensureProtocol, getSiteOptionsByKey } from 'src/utils';
import Post from 'src/components/Post';
import LoadMore from 'src/components/LoadMore';
import PostSidebar from 'src/components/PostSidebar';

import {
  HomeFeaturedPostQueryDocument,
  HomeFeaturedPostQueryQuery,
  PostListQueryDocument,
  SiteOptionsDocument,
  SiteOptionsQuery,
} from 'src/generated';
import getServerApolloClient from 'src/services/apollo/getServerApolloClient';
import { addApolloState } from 'src/services/apollo/client';
import usePostListQuery from 'src/components/PostList/usePostListQuery';
import { layoutDataContext } from 'src/utils/LayoutData';

import styles from './index.module.scss';

function getMetadata(
  options: SiteOptionsQuery | null | undefined,
  featuredPostsData: HomeFeaturedPostQueryQuery | null | undefined,
) {
  const meta: MetaHTMLAttributes<HTMLMetaElement>[] = [
    { property: 'og:title', content: site.name },
    { property: 'og:url', content: `${SITE_URL}/` },
    { property: 'og:image', content: ensureProtocol(logo.src) },
    { property: 'og:image:width', content: String(logo.width) },
    { property: 'og:image:height', content: String(logo.height) },
  ];

  const jsonLd: WithContext<Blog> = {
    '@context': 'https://schema.org',
    '@type': 'Blog',
    '@id': '#blog',
    name: site.name,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${site.protocol}://${site.domain}/?s={search_term_string}`,
      // 'query-input': 'required name=search_term_string',
    },
  };

  if (!options?.options) return { meta, jsonLd };

  const siteOptions = getSiteOptionsByKey(options.options);

  const { description } = siteOptions;

  if (description) {
    meta.push(
      { name: 'description', content: description },
      { property: 'og:description', content: description },
    );
  }

  if (
    featuredPostsData?.blogPosts?.nodes &&
    featuredPostsData.blogPosts?.nodes.length > 0
  ) {
    meta.push({
      property: 'og:updated_time',
      content: featuredPostsData.blogPosts?.nodes?.[0].nodePtr?.modifiedAt,
    });
  }

  return { meta, jsonLd };
}

export default function Home() {
  const { data: featuredPostsData } = useQuery(HomeFeaturedPostQueryDocument);

  const {
    data: postsData,
    loading: isLoadingPosts,
    fetchMore,
  } = usePostListQuery({ pageSize: 5, initialLimit: 4, initialOffset: 1 });

  const { isLoaded } = useContext(layoutDataContext);
  const { data: siteOptionsData } = useQuery(SiteOptionsDocument, {
    skip: !isLoaded,
  });

  const { meta, jsonLd } = getMetadata(siteOptionsData, featuredPostsData);

  const showLoadMore = !!postsData?.blogPosts?.pageInfo?.hasNextPage;

  return (
    <main
      id='main'
      role='main'
      className={classNames('site-main', styles.root)}
    >
      <Head>
        <title>{site.name}</title>
        {meta.map((m) => (
          <meta key={m.property || m.name} {...m} />
        ))}
        <script
          type='application/ld+json'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
      </Head>

      {(featuredPostsData?.blogPosts?.nodes || []).map((post) => (
        <Post
          key={post.nodePtrId}
          post={post}
          imageSizes={[
            '(min-width: 112.5em) 60.5rem', // 87.5rem - 25rem - 2rem
            '(min-width: 102.9em) 65.5rem', // 87.5rem - 20rem - 2rem
            '(min-width: 87.5em) calc(85vw - 20rem - 2rem)',
            '(min-width: 54em) calc(85vw - 15rem - 2rem)',
            '(min-width: 50em) 29rem',
            '100vw',
          ]}
          isSummary
          isFeatured
        />
      ))}

      {(postsData?.blogPosts?.nodes || []).map((p) => (
        <Post key={p.nodePtrId} post={p} isSummary />
      ))}

      {showLoadMore ? (
        <LoadMore isSubmitting={isLoadingPosts} onClick={fetchMore} />
      ) : null}
    </main>
  );
}

Home.getSidebar = () => <PostSidebar />;

export async function getStaticProps() {
  const client = getServerApolloClient();
  await client.query({ query: HomeFeaturedPostQueryDocument });
  await client.query({
    query: PostListQueryDocument,
    variables: { limit: 4, offset: 1 },
  });
  return addApolloState(client, { props: {} });
}
